.login-page-wrap {
  background-color: $color-primary;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &__top {
    // position: sticky;
    // top: 0;
    // z-index: 2;
  }

  &__logo {
    width: 100px;
  }

  &__content {
    background-color: #fff;
    flex-grow: 1;
    border-top-left-radius: 2.5rem;
    display: flex !important;
    flex-direction: column;
    position: relative;

    &.fullscreen {
      height: 100vh;
    }
  }

  &__title {
    position: relative;
    &::after {
      content: "";
      height: calculate-rem(6px);
      width: calculate-rem(72px);
      background-color: $pink;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
    }
  }

  &__illustration-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    // margin-left: auto;
    // margin-right: auto;
    // width: 100%;
    // max-width: 444px;
    // z-index: 1;
  }

  &__illustration-col-left {
    flex: 0 0 60%;
    max-width: 60%;
    img {
      width: 100%;
    }
  }

  &__illustration-col-right {
    flex: 0 0 40%;
    max-width: 40%;
    img {
      width: 100%;
    }
  }

  &__btn {
    z-index: 2;
  }
}
