//align
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-red {
  color: $color-red-100;
}

.bg-gradient-20 {
  height: 5px;
  display: flex;
  background: $gradient-color;
}

.color-white {
  color: $color-white;
}

.font-bold {
  font-weight: bold!important;
}

.color-cyan {
  color: $cyan!important;
}

.color-primary{
  color: $color-primary !important;
}

.color-pink{
  color: $pink !important;
}

.text-underline {
  text-decoration: underline!important;
}

.h-5{
  height: 5px;
}

.h-10{
  height: 10px;
}

.text-bold{
  font-weight: bold !important;
}