.gallery{

    &__container{
        overflow: hidden;
    }
    
    &__grid{
        height: 400px !important;
        @include media-breakpoint-down(xs){
            height: 200px !important;
        }
    }   

    &__item{
       height: 100% !important;

       .MuiGridListTile-tile{
           background: $m-color_23;
       }
        .MuiGridListTileBar-titleWrap{
                margin-left: 0;
                display: none;
            }

            .MuiGridListTileBar-actionIcon{
                width: 100%;
                display: flex;
                margin-left: 0;
                justify-content: space-between;
                align-items: center;
            }
    }

    &__img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}