.btn-xl {
  padding: 18px 22px !important;
}

.btn-loader {
  width: 24px;
  height: 24px;
}

.btn-primary {
  background-color: $color-primary !important;
  color: $color-white !important;
}

.btn-warning {
  background-color: $color-warning !important;
  color: $color-white !important;
}

.btn-success {
  background-color: $color-success !important;
  color: $color-white !important;
}

.btn-info {
  background-color: $color-info !important;
  color: $color-white !important;
}

.btn-fab{
  position: fixed !important;
  bottom: 16px;
  right: 16px;
}