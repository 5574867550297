.card {
  .MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }

  &.card-gradient {
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 5px;
      background: $gradient-color;
    }
  }

}

.MuiPaper-root {
}
