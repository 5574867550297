::-webkit-scrollbar {
//   width: 2px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $m-color_1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $m-color-5;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $m-color-5;
}