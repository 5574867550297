.MuiDrawer-paper {
  max-width: 80%;
}

.MuiAppBar-positionFixed{
  top: 8px !important;
}

.page-wrapper{
  &__top{
    position: fixed;
    top: 0;
    width: 100%;
    height: 8px;
    background-color: $color-primary;
    z-index: 1100;
  }

  &__illustration{
    position: fixed;
    bottom: 0;
    z-index: -1;
    width: 100%;
    left: 0;
    
    &-left{
      flex: 0 0 60%;
      max-width: 60%;
      opacity: 0.5;
      img{
        width: 100%;
      }
    }

    &-right{
      opacity: 0.1;
      flex: 0 0 40%;
      max-width: 40%;
      img{
        width: 100%;
      }
    }

    &-gradient{
      opacity: 0.1;
    }
  }
}

.drawer{
  &__title{
    h6{
      font-weight: bold !important;
    }
  }
}
