$color-red-100: #f44336;
//	===============================
//			@Import	Colors
//	===============================

$color-white: #fff;
$color-black: #000;
$color-primary: #0e2b63;
$color-info:#00b1eb;
$color-success: #50af47;
$color-warning: #ef7d00;
$color-danger: #ff5858;
$color-secondary: #5a328a;
$color-dark: #3b3f5c;
$color-ghost: #ced4da;
$color-boulder: #777;
$color-bright-turquoise: #2aebcb;
$color-tahiti-gold: #df8505;
$color-red: #c00;
$color-tall-poppy: #a9302a;
$color-tundora: #484848;
$color-mountain-meadow: #17c678;
$color-fruit-salad: #499249;
$color-royal-blue: #6f51ea;
$color-jungle-green: #2ea37d;
$color-golden-grass: #dea82a;
$color-cerulean: #009eda;
$color-gun-powder: #454656;
$color-nobel: #b3b3b3;

// gradient
$color-azure-radiance: #0081ff;
$color-blue-ribbon: #0045ff;
$color-dodger-blue: #04befe;
$color-dodger-bluish: #4481eb;
$color-keppel: #3cba92;
$color-gossamer: #0ba360;

$color-carrot-orange: #f09819;
$color-persimmon: #ff5858;

$color-cornflower-blue: #7579ff;
$color-electric-violet: #b224ef;

$color-puce: #d09693;
$color-red-violet: #c71d6f;

$color-san-juan: #2b5876;
$color-east-bay: #4e4376;

$l-primary: #c2d5ff;
$l-info: #bae7ff;
$l-success: #e6ffbf;
$l-warning: #ffeccb;
$l-danger: #ffe1e2;
$l-secondary: #dccff7;
$l-dark: #acb0c3;

// 	=================
//		More Colors
//	=================

$m-color_1: #f1f2f3;
$m-color_2: #ebedf2;

$m-color_3: #e0e6ed;
$m-color_4: #bfc9d4;
$m-color_5: #d3d3d3;
$m-color_6: #888ea8;
$m-color_7: #506690;
$m-color_8: #555555;
$m-color_9: #515365;
$m-color_10: #0e1726;
$m-color_11: #607d8b;
$m-color_12: #1b2e4b;
$m-color_13: #25d5e4;
$m-color_14: #009688;
$m-color_15: #ffbb44;
$m-color_16: #e95f2b;
$m-color_17: #f8538d;
$m-color_18: #191e3a;
$m-color_19: #060818;
$m-color_20: #fafafa;
$m-color_21: #445ede;
$m-color_22: #304aca;
$m-color-23: #eeeff0;

//	========================
//		Color Defination
//	========================

$body-color: $m-color_1;
$dark-blue: $color-primary;
$mid-blue: #004f9f;
$pink: #e72582;
$cyan: #00b1eb;
$light-green: #afca0b;
$yellow: #ffbb00;
$orange: #ef7d00;
$purple: #5a328a;
$green: #50af47;

$gradient-color: linear-gradient(90deg, #0e2b63 30.4785%, rgba(0, 0, 0, 0) 30.4785%),
linear-gradient(90deg, #004f9f 52.15788%, rgba(0, 0, 0, 0) 52.15788%), 
linear-gradient(90deg, #00b1eb 64.72807%, rgba(0, 0, 0, 0) 64.72807%), 
linear-gradient(90deg, #ef7d00 73.22551%, rgba(0, 0, 0, 0) 73.22551%), 
linear-gradient(90deg, #ffbb00 79.62792%, rgba(0, 0, 0, 0) 79.62792%), 
linear-gradient(90deg, #50af47 86.69237%, rgba(0, 0, 0, 0) 86.69237%), 
linear-gradient(90deg, #afca0b 92.0305%, rgba(0, 0, 0, 0) 92.0305%), 
linear-gradient(90deg, #5a328a 97.0083%, rgba(0, 0, 0, 0) 97.0083%), 
linear-gradient(90deg, #e72582 100%, rgba(0, 0, 0, 0) 100%)