.pill{
  padding: 4px 12px;
  display: inline-flex;
  border-radius: 16px;
  margin-right: 8px;

  &:last-child{
    margin-right: 0;
  }

  svg{
    fill: $color-white;
  }

  &-primary{
     background-color: $color-primary;
  }

  &-secondary{
     background-color: $color-secondary;
  }

  &-info{
     background-color: $color-info;
  }

  &-success{
     background-color: $color-success;
  }

  &-warning{
     background-color: $color-warning;
  }

  &-danger{
     background-color: $color-danger;
  }
}