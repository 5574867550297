.caption {
  font-family: $font-primary;
  font-size: 0.75rem;
  line-height: 1.5;
}

.text-error {
    color: $color-danger;
}

.text-bold {
    font-weight: bold;
}